import { NgModule, APP_INITIALIZER, Injector, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule } from '@angular/forms';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RouteReuseStrategy } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';

import { AuthService } from '@nexus/auth/auth.service';
import { ApiService } from '@nexus/services/api.service';
import { ScrollService } from '@nexus/services/scroll.service';
import { AutosaveService } from '@nexus/services/autosave.service';
import { NotificationService } from '@nexus/services/notification.service';
import { DateFilterService } from '@nexus/services/date-filter.service';
import { ServiceLocator } from '@nexus/services/service-locator';
import { services } from '@nexus/services/map';
import { FormsDisablerService } from '@nexus/services/forms-disabler.service';
import { BaseComponent } from '@nexus/components/base/base.component';
import { SnackBarComponent } from '@nexus/components/snack-bar/snack-bar.component';
import { TenantService } from '@nexus/auth/tenant.service';
import { environment } from '../environments/environment';
import { TranslocoRootModule } from './transloco-root.module';
import { FormBaseComponent } from './components/form-base/form-base.component';
import { RequestInterceptorService } from './services/request-interceptor.service';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { JwtInterceptor } from './core/jwt-interceptor';
import { CustomReuseStrategy } from './custom-route-reuse-strategy';

export function preloadi18n(transloco: TranslocoService) {
  return () => {
    transloco.setActiveLang('en');
    return transloco.load('en').toPromise()
      .catch(err => console.error('Transloco load failed:', err));
  };
}

@NgModule({ declarations: [AppComponent, FormBaseComponent, BaseComponent, SnackBarComponent],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [CoreModule,
        SharedModule,
        AuthModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgSelectModule,
        FormsModule,
        TranslocoRootModule,
        GoogleMapsModule,
        NgxGoogleAnalyticsModule.forRoot('G-1M5TY0L66Q')]
        , providers: [
        JwtHelperService,
        ApiService,
        ScrollService,
        FormsDisablerService,
        NotificationService,
        DateFilterService,
        AutosaveService,
        AuthService,
        TenantService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptorService,
            multi: true,
        },
        // {
        //     provide: ErrorHandler,
        //     useValue: Sentry.createErrorHandler({
        //         showDialog: false,
        //         logErrors: true,
        //         extractor: GlobalErrorHandler._defaultExtractor,
        //     }),
        // },
        // { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        // {
        //     provide: Sentry.TraceService,
        //     deps: [Router],
        // },
        {
            provide: RouteReuseStrategy,
            useClass: CustomReuseStrategy,
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: preloadi18n,
            deps: [TranslocoService],
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
  constructor() {
    ServiceLocator.injector = Injector.create({
      providers: Object.keys(services).map((key) => ({
        provide: services[key].provide,
        useClass: services[key].provide,
        deps: services[key].deps,
      })),
    });
  }
}
